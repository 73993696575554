const MenuItems = [
    {
        href: '/',
        title: 'Home',
    },
    {
        href: 'projects',
        title: 'Projects',
    },
    {
        href: 'gallery',
        title: 'Gallery',
    }, 
    {
         href: 'blog',
         title: 'Blog',
    }
];

export default MenuItems; 