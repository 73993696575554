const TagInfo = [
    "minecraft",
    "programming",
    "coding",
    "notes", 
    "leetcode",
    "dynamic programming",
    "arrays",
    "linked lists",
    "hashing",
    "website",
    
];

export default TagInfo; 